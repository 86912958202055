import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/avatar-1.svg",
    userName: "Fukushima Kazu",
    subtitle: "Student",
    review:
      "RGC Digital Solutions' service is excellent! They can make a professional-looking web app though it is just simple. I am satisfied with the app's design, functionality, and overall usability. I recommend this service to my friends and colleagues who want to create their website/web app and do graphic designing. In addition, the staff is very accommodating to its client. ",
  },
  {
    id: 2,
    avatar: "images/avatar-2.svg",
    userName: "Sean",
    subtitle: "Student",
    review:
      "Great service, puts the client on priority",
  },
  {
    id: 3,
    avatar: "images/avatar-2.svg",
    userName: "Riderdee",
    subtitle: "Vlogger",
    review:
      "Exceptional tech support",
  }
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="Clients & Review" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
