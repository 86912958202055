import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";

const priceboxData = [
  {
    id: 1,
    title: "Standard",
    icon: "images/price-1.svg",
    content: [
      {
        id: 0,
        text: "Select top templates for a stunning website. Your choice matters.",
      },
      {
        id: 2,
        text: "1-year Subscription",
      },
    ],
    price: 300,
    best: false,
    badge: false,
  },
  {
    id: 2,
    title: "Premium",
    icon: "images/price-2.svg",
    content: [
      {
        id: 1,
        text: "Custom layouts. Advanced SEO. Empower your online presence.",
      },
      {
        id: 2,
        text: "2-year Subscription",
      },
    ],
    price: 280,
    best: false,
    badge: "Recommended",
  },
  {
    id: 3,
    title: "Deluxe",
    icon: "images/price-3.svg",
    content: [
      { id: 1, text: "Ultimate Upgrade: Elevate your website to unparalleled excellence." },
      { id: 2, text: "3-year Subscription" },
    ],
    price: 250,
    best: false,
    badge: false,
  },
];

function Pricing() {
  return (
    <section id="prices">
      <div className="container">
        <Pagetitle title="Website Plans" />
        <div className="row align-items-center">
          {priceboxData.map((pricebox) => (
            <div key={pricebox.id} className="col-md-4">
              <Pricebox priceboxItem={pricebox} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
