import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/TW.png",
    title: "Front-end Development",
    content:
      "Creating interactive and responsive websites using HTML, CSS, and JavaScript.",
    color: "#4942E4",
    contentColor: "dark",
  },
  {
    id: 2,
    icon: "images/L.png",
    title: "Back-end Development",
    content:
      "Building the server-side functionality of the website using PHP.",
    color: "#FF6969",
    contentColor: "light",
  },
  {
    id: 3,
    icon: "images/woo.png",
    title: "E-commerce Solutions",
    content:
      "Building online stores with secure payment gateways, shopping carts, and inventory management systems.",
    color: "#B799FF",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
