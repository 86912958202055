import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor of Science in & Information Technology",
    years: "2013 - 2017",
    content:
      "City of Malabon University",
  }
];

const experienceData = [
  {
    id: 1,
    title: "Associate Technical SEO Head",
    years: "Jul 2023 - Current",
    content:
      "ExaWeb Corporation",
  },
  {
    id: 2,
    title: "Fullstacked Web Developer",
    years: "Nov 2022 - May 2023",
    content:
      "Outsourced Accelerator",
  },
  {
    id: 3,
    title: "Fullstacked Web Developer",
    years: "Nov 2020 - Nov 2022",
    content:
      "Zesto Corporation",
  },
  {
    id: 4,
    title: "Fullstacked Web Developer",
    years: "May 2017 - Aug 2020",
    content:
      "Pandayan Bookshop Inc.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
